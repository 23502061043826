import { CaretDown } from '@phosphor-icons/react/dist/ssr';
import { type CollapsibleProps as RadixCollapsibleProps } from '@radix-ui/react-collapsible';
import * as CollapsiblePrimitive from '@radix-ui/react-collapsible';
import clsx from 'clsx';
import React from 'react';
import { useState } from 'react';

const RadixCollapsible = CollapsiblePrimitive.Root;

const CollapsibleTrigger = CollapsiblePrimitive.CollapsibleTrigger;

const CollapsibleContent = CollapsiblePrimitive.CollapsibleContent;

/* ---------------------------------- Component --------------------------------- */

const CollapsibleTitle = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => <div ref={ref} className={clsx('font-semibold', className)} {...props} />
);
CollapsibleTitle.displayName = 'CollapsibleTitle';

/* ---------------------------------- Component --------------------------------- */

const CollapsibleSubtitle = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => <div ref={ref} className={clsx('text-secondary text-sm', className)} {...props} />
);
CollapsibleSubtitle.displayName = 'CollapsibleSubtitle';

/* ---------------------------------- Type --------------------------------- */

export interface CollapsibleProps extends RadixCollapsibleProps {
  /** Icon to the left of the title */
  icon?: React.ReactNode;
  /** Title of the collapsible */
  title: string;
  /** Subtitle of the collapsible */
  subtitle?: string;
  /** Whether to show the collapsible bottom border when open
   * @default true
   */
  bordered?: boolean;
  children: React.ReactNode;
  className?: string;
  /** Variant of the collapsible
   *  @default 'outline'
   */
  variant?: 'outline' | 'filled' | 'filled-high';
}

/* ---------------------------------- Component --------------------------------- */

function Collapsible({
  children,
  title,
  icon,
  subtitle,
  bordered = true,
  className = '',
  variant = 'outline',
  ...props
}: CollapsibleProps) {
  const [open, setOpen] = useState(props.defaultOpen);

  return (
    <RadixCollapsible
      onOpenChange={(open) => {
        setOpen(open);
        props.onOpenChange?.(open);
      }}
      className={className}
      {...props}
    >
      <CollapsibleTrigger asChild>
        <div
          className={clsx('flex w-full items-center gap-2 px-6 py-5 rounded-t-radius', {
            'rounded-b-none': open,
            'rounded-b-radius': !open,
            'border-b-0': !bordered && open,
            'border border-stroke bg-background': variant === 'outline',
            'bg-container': variant === 'filled',
            'bg-container-high': variant === 'filled-high',
          })}
        >
          {icon}
          <div className="flex flex-1 flex-col">
            <CollapsibleTitle>{title}</CollapsibleTitle>
            {subtitle && <CollapsibleSubtitle>{subtitle}</CollapsibleSubtitle>}
          </div>
          <CaretDown
            size={16}
            className={clsx('ml-auto', {
              'rotate-180': open,
            })}
          />
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent
        className={clsx(
          'border-t-none border-t-0 rounded-b-radius rounded-t-none p-5 data-[state=closed]:animate-collapsible-up data-[state=open]:animate-collapsible-down',
          {
            'border border-stroke bg-background': variant === 'outline',
            'bg-container': variant === 'filled',
            'bg-container-high': variant === 'filled-high',
          }
        )}
      >
        {children}
      </CollapsibleContent>
    </RadixCollapsible>
  );
}

export { CollapsibleContent, CollapsibleTrigger, RadixCollapsible };

export default Collapsible;
