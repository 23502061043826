import { CaretDoubleLeft, CaretDoubleRight, CaretLeft, CaretRight, DotsThree } from '@phosphor-icons/react/dist/ssr';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import React from 'react';
import { useCallback } from 'react';
import { twMerge } from 'tailwind-merge';

import Select from './Select';

/* ---------------------------------- Component --------------------------------- */

const PaginationNavigation = ({ className, ...props }: React.ComponentProps<'nav'>) => (
  <nav role="navigation" aria-label="pagination" className={clsx('flex justify-center', className)} {...props} />
);
PaginationNavigation.displayName = 'PaginationNavigation';

/* ---------------------------------- Component --------------------------------- */

const PaginationContent = React.forwardRef<HTMLUListElement, React.ComponentProps<'ul'>>(
  ({ className, ...props }, ref) => (
    <ul ref={ref} className={clsx('flex flex-row items-center gap-1', className)} {...props} />
  )
);
PaginationContent.displayName = 'PaginationContent';

/* ---------------------------------- Component --------------------------------- */

const PaginationItem = React.forwardRef<HTMLLIElement, React.ComponentProps<'li'>>(({ className, ...props }, ref) => (
  <li ref={ref} className={clsx('', className)} {...props} />
));
PaginationItem.displayName = 'PaginationItem';

/* ---------------------------------- Type --------------------------------- */

export interface PaginationLinkProps
  extends Pick<PaginationProps, 'size' | 'color'>,
    Omit<React.ComponentProps<'a'>, 'size' | 'color'> {
  /** Display the link as selected */
  isActive?: boolean;
  /** Display the link as disabled */
  disabled?: boolean;
  /** Display the link as bordered */
  bordered?: boolean;
}

/* ---------------------------------- Component --------------------------------- */

export const paginationLinkClassnames = cva(
  [
    'flex',
    'items-center',
    'justify-center',
    'rounded-radius',
    'disabled:pointer-events-none',
    'transition-colors',
    'select-none',
    'border-stroke-disabled',
    'text-subtle',
  ],
  {
    variants: {
      color: {
        primary: 'focus:primary-focus hover:bg-primary-container hover:text-on-primary-container',
        neutral: 'focus:neutral-focus hover:bg-neutral-accent hover:text-on-neutral-container',
      },
      size: {
        sm: 'h-7 w-7 text-sm',
        md: 'h-8 w-8 text-sm',
        lg: 'h-10 w-10 text-base',
      },
      isActive: {
        true: 'font-semibold',
        false: '',
      },
      bordered: {
        true: 'border',
        false: '',
      },
      disabled: {
        true: 'bg-disabled text-on-disabled pointer-events-none',
        false: '',
      },
    },
    compoundVariants: [
      {
        color: 'primary',
        isActive: true,
        className: ['text-primary', 'border-primary', 'border'],
      },
      {
        color: 'neutral',
        isActive: true,
        className: ['text-neutral', 'border-neutral', 'border'],
      },
    ],
  }
);

const PaginationLink = ({ className, isActive, size, color, disabled, bordered, ...props }: PaginationLinkProps) => (
  <a
    aria-current={isActive ? 'page' : undefined}
    className={twMerge(
      clsx(
        paginationLinkClassnames({ color, size: size, isActive: isActive, disabled, bordered }),
        { 'h-7 text-xs': size === 'sm', 'h-8': size === 'md', 'h-10': size === 'lg' },
        className
      )
    )}
    aria-disabled={disabled}
    {...props}
  />
);
PaginationLink.displayName = 'PaginationLink';

/* ---------------------------------- Component --------------------------------- */

const PaginationPrevious = ({
  size,
  color,
  disabled = true,
  ...props
}: React.ComponentProps<typeof PaginationLink> & {
  disabled?: boolean;
}) => (
  <PaginationLink
    aria-label="Go to previous page"
    className="border-none"
    color={color}
    size={size}
    disabled={disabled}
    {...props}
  >
    <CaretLeft className="h-4 w-4" />
  </PaginationLink>
);
PaginationPrevious.displayName = 'PaginationPrevious';

/* ---------------------------------- Component --------------------------------- */

const PaginationNext = ({
  size,
  color,
  disabled,
  ...props
}: React.ComponentProps<typeof PaginationLink> & {
  disabled?: boolean;
}) => (
  <PaginationLink
    aria-label="Go to previous page"
    className="border-none"
    color={color}
    size={size}
    disabled={disabled}
    {...props}
  >
    <CaretRight className="h-4 w-4" />
  </PaginationLink>
);
PaginationNext.displayName = 'PaginationNext';

/* ---------------------------------- Component --------------------------------- */

const PaginationEllipsis = ({
  className,
  color,
  type,
  ...props
}: React.ComponentProps<'span'> & {
  className?: string;
  color?: 'primary' | 'neutral';
  type: 'previous' | 'next';
}) => (
  <span
    aria-hidden
    className={clsx(
      'group flex h-7 w-7 items-center justify-center transition-colors',

      className
    )}
    {...props}
  >
    <DotsThree className="h-4 w-4 group-hover:hidden" />
    {type === 'previous' ? (
      <CaretDoubleLeft
        className={clsx('hidden h-4 w-4 group-hover:block', {
          'hover:fill-primary': color === 'primary',
          'hover:fill-neutral': color === 'neutral',
        })}
      />
    ) : (
      <CaretDoubleRight
        className={clsx('hidden h-4 w-4 group-hover:block', {
          'hover:fill-primary': color === 'primary',
          'hover:fill-neutral': color === 'neutral',
        })}
      />
    )}

    <span className="sr-only">{type === 'next' ? 'More pages' : 'Fewer pages'}</span>
  </span>
);
PaginationEllipsis.displayName = 'PaginationEllipsis';

/* ---------------------------------- Type --------------------------------- */

type PaginationProps = React.ComponentProps<'nav'> & {
  /** Size of the pagination
   * @default 'md'
   */
  size?: 'sm' | 'md' | 'lg';
  /** Color of the pagination
   * @default 'primary'
   */
  color?: 'primary' | 'neutral';
  /** Default page to display
   * @default 1
   */
  defaultPage?: number;
  /** Callback when the page changes */
  onPageChange?: (page: number) => any;
  /** Total number of pages
   * @default 5
   */
  totalPages?: number;
  /** Default page size
   * @default 10
   */
  defaultPageSize?: 10 | 20 | 50 | 100;
  /** Callback when the rows per page changes
   * @default 10
   */
  onRowsPerPageChange?: (rows: 10 | 20 | 50 | 100) => any;
  /** Display pagination links as bordered
   * @default true
   */
  bordered?: boolean;
};

/* ---------------------------------- Component --------------------------------- */

export default function Pagination({
  className,
  size = 'md',
  color = 'primary',
  defaultPage = 1,
  onPageChange,
  totalPages = 5,
  defaultPageSize = 10,
  onRowsPerPageChange,
  bordered = true,
  ...props
}: PaginationProps) {
  const [currentPage, setCurrentPage] = React.useState(Math.max(1, Math.min(defaultPage, totalPages)));
  const isPreviousEllipsis = currentPage - 3 > 1;
  const isNextEllipsis = currentPage + 3 < totalPages;
  const showEllipsis = totalPages > 7;

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
    onPageChange?.(page);
  }, []);

  return (
    <div className={clsx('flex gap-2', className)}>
      <PaginationNavigation {...props} className="w-fit">
        <PaginationContent>
          <PaginationItem>
            <PaginationPrevious
              size={size}
              color={color}
              type={'previous'}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
            />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink
              size={size}
              color={color}
              onClick={() => handlePageChange(1)}
              isActive={currentPage === 1}
              bordered={bordered}
            >
              1
            </PaginationLink>
          </PaginationItem>
          {showEllipsis && isPreviousEllipsis && (
            <PaginationItem onClick={() => handlePageChange(Math.max(1, currentPage - 5))}>
              <PaginationEllipsis color={color} type={'previous'} />
            </PaginationItem>
          )}
          {totalPages &&
            Array.from({ length: totalPages > 7 ? 5 : totalPages - 2 }, (_, index) => {
              const isAtBeginning = currentPage - 3 <= 1;
              const isAtEnd = currentPage + 3 > totalPages;
              const page = !showEllipsis
                ? index + 2
                : isAtBeginning
                ? index + 2
                : isAtEnd
                ? totalPages - Math.min(currentPage - 1, 5) + index
                : currentPage - 2 + index;
              return (
                <PaginationItem onClick={() => handlePageChange(page)} key={`page-${page}`}>
                  <PaginationLink isActive={currentPage === page} size={size} color={color} bordered={bordered}>
                    {page}
                  </PaginationLink>
                </PaginationItem>
              );
            })}

          {showEllipsis && isNextEllipsis && (
            <PaginationItem onClick={() => handlePageChange(Math.min(totalPages, currentPage + 5))}>
              <PaginationEllipsis color={color} type={'next'} />
            </PaginationItem>
          )}

          <PaginationItem onClick={() => handlePageChange(totalPages)}>
            <PaginationLink size={size} color={color} isActive={currentPage === totalPages} bordered={bordered}>
              {totalPages}
            </PaginationLink>
          </PaginationItem>

          <PaginationItem>
            <PaginationNext
              size={size}
              color={color}
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
            />
          </PaginationItem>
        </PaginationContent>
      </PaginationNavigation>
      {onRowsPerPageChange && (
        <Select
          className={clsx('w-20 border-stroke', {
            'w-16': size === 'sm' || size === 'md',
            'w-20': size === 'lg',
          })}
          triggerClassName={clsx('border-stroke py-0', {
            'h-7 px-2': size === 'sm',
            'h-8 px-2': size === 'md',
            'h-10 text-base': size === 'lg',
          })}
          defaultValue={defaultPageSize.toString()}
          options={[
            {
              label: '10',
              value: '10',
            },
            {
              label: '20',
              value: '20',
            },
            {
              label: '50',
              value: '50',
            },
            {
              label: '100',
              value: '100',
            },
          ]}
          onValueChange={(value) => {
            onRowsPerPageChange?.(parseInt(value) as 10 | 20 | 50 | 100);
            handlePageChange(1);
          }}
        />
      )}
    </div>
  );
}

export {
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNavigation,
  PaginationNext,
  PaginationPrevious,
};
