import { ChevronRightIcon, DotsHorizontalIcon, SlashIcon } from '@radix-ui/react-icons';
import { Slot } from '@radix-ui/react-slot';
import clsx from 'clsx';
import React from 'react';
import { twMerge } from 'tailwind-merge';

/* ---------------------------------- Component --------------------------------- */

const Breadcrumb = React.forwardRef<HTMLElement, React.ComponentPropsWithoutRef<'nav'>>(({ ...props }, ref) => (
  <nav ref={ref} aria-label="breadcrumb" {...props} />
));
Breadcrumb.displayName = 'Breadcrumb';

/* ---------------------------------- Component --------------------------------- */

const BreadcrumbList = React.forwardRef<HTMLOListElement, React.ComponentPropsWithoutRef<'ol'>>(
  ({ className, ...props }, ref) => (
    <ol
      ref={ref}
      className={twMerge(clsx('flex flex-wrap items-center gap-2 break-words text-subtle', className))}
      {...props}
    />
  )
);
BreadcrumbList.displayName = 'BreadcrumbList';

/* ---------------------------------- Component --------------------------------- */

const BreadcrumbItem = React.forwardRef<HTMLLIElement, React.ComponentPropsWithoutRef<'li'>>(
  ({ className, ...props }, ref) => (
    <li ref={ref} className={twMerge(clsx('inline-flex items-center gap-1', className))} {...props} />
  )
);
BreadcrumbItem.displayName = 'BreadcrumbItem';

/* ---------------------------------- Component --------------------------------- */

const BreadcrumbLink = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithoutRef<'a'> & {
    asChild?: boolean;
  }
>(({ asChild, className, ...props }, ref) => {
  const Comp = asChild ? Slot : 'a';

  return (
    <Comp
      ref={ref}
      className={twMerge(
        clsx(
          'focus:primary-focus flex items-center gap-2 rounded-md transition-colors hover:text-foreground hover:underline',
          className
        )
      )}
      {...props}
    />
  );
});
BreadcrumbLink.displayName = 'BreadcrumbLink';

/* ---------------------------------- Component --------------------------------- */

const BreadcrumbPage = React.forwardRef<HTMLSpanElement, React.ComponentPropsWithoutRef<'span'>>(
  ({ className, ...props }, ref) => (
    <span
      ref={ref}
      role="link"
      aria-disabled="true"
      aria-current="page"
      className={twMerge(clsx('font-normal text-foreground', className))}
      {...props}
    />
  )
);
BreadcrumbPage.displayName = 'BreadcrumbPage';

/* ---------------------------------- Component --------------------------------- */

const BreadcrumbSeparator = ({ children, className, ...props }: React.ComponentProps<'li'>) => (
  <li role="presentation" aria-hidden="true" className={twMerge(clsx('[&>svg]:size-3.5', className))} {...props}>
    {children ?? <ChevronRightIcon />}
  </li>
);
BreadcrumbSeparator.displayName = 'BreadcrumbSeparator';

/* ---------------------------------- Component --------------------------------- */

const BreadcrumbEllipsis = ({ className, ...props }: React.ComponentProps<'span'>) => (
  <span
    role="presentation"
    aria-hidden="true"
    className={twMerge(clsx('flex h-9 w-9 items-center justify-center', className))}
    {...props}
  >
    <DotsHorizontalIcon className="h-4 w-4" />
    <span className="sr-only">More</span>
  </span>
);
BreadcrumbEllipsis.displayName = 'BreadcrumbElipssis';

/* ---------------------------------- Types --------------------------------- */

export interface BreadCrumb {
  /** Icon to the left of name */
  iconLeading?: React.ReactNode;
  /** Icon to the left of the name */
  iconTrailing?: React.ReactNode;
  /** Name of the breadcrumb */
  name: string;
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export interface BreadCrumbsProps {
  breadcrumbs: BreadCrumb[];
}

/* ---------------------------------- Component --------------------------------- */

const Breadcrumbs = React.forwardRef<HTMLElement, React.ComponentPropsWithoutRef<'nav'> & BreadCrumbsProps>(
  ({ breadcrumbs, ...props }, ref) => (
    <Breadcrumb ref={ref} {...props}>
      <BreadcrumbList>
        {breadcrumbs.map((breadcrumb, index) => {
          const isLast = index === breadcrumbs.length - 1;
          return (
            <React.Fragment key={breadcrumb.href}>
              <BreadcrumbItem>
                <BreadcrumbLink
                  href={breadcrumb.href}
                  onClick={breadcrumb.onClick}
                  className={clsx(isLast && 'text-foreground')}
                >
                  {breadcrumb.iconLeading}
                  {breadcrumb.name}
                  {breadcrumb.iconTrailing}
                </BreadcrumbLink>
              </BreadcrumbItem>
              {!isLast && (
                <BreadcrumbSeparator>
                  <SlashIcon />
                </BreadcrumbSeparator>
              )}
            </React.Fragment>
          );
        })}
      </BreadcrumbList>
    </Breadcrumb>
  )
);
Breadcrumbs.displayName = 'Breadcrumbs';

export {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
};

export default Breadcrumbs;
