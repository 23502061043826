import clsx from 'clsx';
import React from 'react';
import { twMerge } from 'tailwind-merge';

/* ---------------------------------- Component --------------------------------- */

const CardContainer = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={twMerge(clsx('flex w-fit flex-col rounded-radius-md text-foreground', className))}
      {...props}
    />
  )
);
CardContainer.displayName = 'CardContainer';

/* ---------------------------------- Type --------------------------------- */

export interface CardHeaderProps {
  /** Alignment of the drawer header
   * @default 'left'
   */
  headerAlignment?: 'left' | 'center';
  /** Icon to the left or above the title */
  icon?: React.ReactNode;
  /** Header icon placement
   * @default 'left'
   */
  iconPlacement?: 'left' | 'top';
  /** Title of the drawer */
  title?: string | React.ReactNode;
  /** Subtitle/description, below the title, of the drawer */
  subtitle?: string | React.ReactNode;
  /** Display a border under the header
   * @default true
   */
  bordered?: boolean;
  className?: string;
}

/* ---------------------------------- Component --------------------------------- */

const CardHeader = ({
  headerAlignment = 'left',
  iconPlacement = 'left',
  icon,
  title,
  subtitle,
  bordered,
  className,
}: CardHeaderProps) => (
  <div
    className={clsx(className, 'flex gap-2 p-6 pb-0', {
      'flex-col': iconPlacement === 'top',
      'justify-center': headerAlignment === 'center',
      'items-center': iconPlacement === 'left' || (iconPlacement === 'top' && headerAlignment === 'center'),
      'border-b border-stroke': bordered,
    })}
  >
    {icon && <span>{icon}</span>}
    <div
      className={clsx('flex flex-col', {
        'items-center': iconPlacement === 'top' && headerAlignment === 'center',
      })}
    >
      {title && <CardTitle>{title}</CardTitle>}
      {subtitle && <CardSubtitle>{subtitle}</CardSubtitle>}
    </div>
  </div>
);
CardHeader.displayName = 'CardHeader';

/* ---------------------------------- Component --------------------------------- */

const CardTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => (
    <h3 ref={ref} className={clsx('text-base font-semibold text-foreground', className)} {...props} />
  )
);
CardTitle.displayName = 'CardTitle';

/* ---------------------------------- Component --------------------------------- */

const CardSubtitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className, ...props }, ref) => <p ref={ref} className={clsx('text-sm text-subtle', className)} {...props} />
);
CardSubtitle.displayName = 'CardSubtitle';

/* ---------------------------------- Component --------------------------------- */

const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={twMerge(clsx('px-6 py-5 overflow-y-auto h-full', className))} {...props} />
  )
);
CardContent.displayName = 'CardContent';

/* ---------------------------------- Component --------------------------------- */

const CardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={clsx('mt-auto flex items-center space-x-2 px-6 py-5', className)} {...props} />
  )
);
CardFooter.displayName = 'CardFooter';

/* ---------------------------------- Type --------------------------------- */

export interface CardProps extends Omit<CardHeaderProps, 'bordered'> {
  /** Alignment of the drawer header
   * @default 'left'
   */
  headerAlignment?: 'left' | 'center';
  /** Content of the card */
  children?: React.ReactNode | React.ReactNode[];
  /** Footer of the card */
  footer?: React.ReactNode | React.ReactNode[];
  /** Type of card
   * @default 'outline'
   */
  type?: 'elevated' | 'outline' | 'filled' | 'filled-high';
  /** Classname of the card container (use this to position the card) */
  className?: string;
  /** Classname of the content container */
  contentClassName?: string;
}

/* ---------------------------------- Component --------------------------------- */

function Card({
  title,
  subtitle,
  children,
  icon,
  footer,
  headerAlignment,
  iconPlacement,
  className,
  contentClassName,
  type = 'outline',
}: CardProps) {
  return (
    <CardContainer
      className={twMerge(
        clsx(className, {
          'shadow-medium bg-background': type === 'elevated',
          'border border-stroke bg-background': type === 'outline',
          'bg-container': type === 'filled',
          'bg-container-high': type === 'filled-high',
        })
      )}
    >
      {title && (
        <CardHeader
          title={title}
          subtitle={subtitle}
          icon={icon}
          headerAlignment={headerAlignment}
          iconPlacement={iconPlacement}
        />
      )}
      <CardContent className={contentClassName}>{children}</CardContent>
      {footer && <CardFooter>{footer}</CardFooter>}
    </CardContainer>
  );
}
Card.displayName = 'Card';

export default Card;
