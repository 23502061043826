import { X } from '@phosphor-icons/react/dist/ssr';
// Note: We are renaming the RadixUI Dialog -> Modal
import * as ModalPrimitive from '@radix-ui/react-dialog';
import clsx from 'clsx';
import React from 'react';

import Button from './Button';

const ModalTrigger = ModalPrimitive.Trigger;

const ModalPortal = ModalPrimitive.Portal;

const ModalClose = ModalPrimitive.Close;

/* ---------------------------------- Component --------------------------------- */

const ModalOverlay = React.forwardRef<
  React.ElementRef<typeof ModalPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof ModalPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <ModalPrimitive.Overlay
    ref={ref}
    className={clsx(
      'fixed inset-0 z-50 bg-overlay data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className
    )}
    {...props}
  />
));
ModalOverlay.displayName = ModalPrimitive.Overlay.displayName;

/* ---------------------------------- Component --------------------------------- */

const ModalContent = React.forwardRef<
  React.ElementRef<typeof ModalPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof ModalPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <ModalPortal>
    <ModalOverlay />
    <ModalPrimitive.Content
      ref={ref}
      className={clsx(
        'fixed left-[50%] top-[50%] z-50 grid w-fit translate-x-[-50%] translate-y-[-50%] rounded-radius-lg bg-background shadow-medium duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]',
        className
      )}
      {...props}
    >
      {children}
    </ModalPrimitive.Content>
  </ModalPortal>
));
ModalContent.displayName = ModalPrimitive.Content.displayName;

/* ---------------------------------- Component --------------------------------- */

const ModalTitle = React.forwardRef<
  React.ElementRef<typeof ModalPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof ModalPrimitive.Title>
>(({ className, ...props }, ref) => (
  <ModalPrimitive.Title ref={ref} className={clsx('text-base-foreground font-semibold', className)} {...props} />
));
ModalTitle.displayName = ModalPrimitive.Title.displayName;

/* ---------------------------------- Component --------------------------------- */

const ModalSubtitle = React.forwardRef<
  React.ElementRef<typeof ModalPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof ModalPrimitive.Description>
>(({ className, ...props }, ref) => (
  <ModalPrimitive.Description ref={ref} className={clsx('text-sm text-secondary', className)} {...props} />
));
ModalSubtitle.displayName = ModalPrimitive.Description.displayName;

/* ---------------------------------- Type --------------------------------- */

interface ModalHeaderProps {
  /** Alignment of the modal header
   * @default 'left'
   */
  headerAlignment?: 'left' | 'center';
  /** Icon to the left or above the title */
  icon?: React.ReactNode;
  /** Header icon placement
   * @default 'left'
   */
  iconPlacement?: 'left' | 'top';
  /** Title of the modal */
  title?: string;
  /** Subtitle/description, below the title, of the modal */
  subtitle?: string;
  /** Display a border under the header
   * @default true
   */
  bordered?: boolean;
  className?: string;
}

/* ---------------------------------- Component --------------------------------- */

const ModalHeader = ({
  headerAlignment = 'left',
  iconPlacement = 'left',
  icon,
  title,
  subtitle,
  bordered = true,
  className,
}: ModalHeaderProps) => (
  <div
    className={clsx(className, 'flex gap-2', {
      'flex-col': iconPlacement === 'top',
      'justify-center': headerAlignment === 'center',
      'items-center': iconPlacement === 'left' || (iconPlacement === 'top' && headerAlignment === 'center'),
      'border-b border-stroke': bordered,
    })}
  >
    {icon && <span>{icon}</span>}
    <div
      className={clsx('flex flex-col', {
        'items-center': iconPlacement === 'top' && headerAlignment === 'center',
      })}
    >
      {title && <ModalTitle>{title}</ModalTitle>}
      {subtitle && <ModalSubtitle>{subtitle}</ModalSubtitle>}
    </div>
  </div>
);
ModalHeader.displayName = 'ModalHeader';

/* ---------------------------------- Component --------------------------------- */

const ModalFooter = ({
  className,
  bordered = true,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  bordered?: boolean;
}) => <div className={clsx(bordered && 'border-t border-stroke', className)} {...props} />;

ModalFooter.displayName = 'ModalFooter';

/* ---------------------------------- Type --------------------------------- */

export interface ModalProps
  extends Omit<ModalHeaderProps, 'bordered'>,
    React.ComponentPropsWithoutRef<typeof ModalPrimitive.Root> {
  /** Add border bottom to the header
   * @default true
   */
  headerBordered?: boolean;
  /** Whether to show the close button
   * @default false
   */
  showClose?: boolean;
  /** Element that opens the modal on click */
  trigger?: React.ReactNode;
  /** Footer of the modal */
  footer?: React.ReactNode;
  /** Add border top to the footer
   * @default false
   */
  footerBordered?: boolean;
  className?: string;
}

/* ---------------------------------- Component --------------------------------- */

const Modal = React.forwardRef<React.ElementRef<typeof ModalPrimitive.Root>, ModalProps>(
  (
    {
      showClose,
      trigger,
      className,
      title,
      subtitle,
      icon,
      iconPlacement = 'left',
      headerAlignment = 'left',
      headerBordered = true,
      footerBordered = true,
      footer,
      ...props
    },
    ref
  ) => (
    <ModalPrimitive.Root {...props}>
      <ModalTrigger asChild>{trigger}</ModalTrigger>
      <ModalContent ref={ref} className={clsx('sm:min-w-96 w-full max-w-lg overflow-hidden', className)}>
        {showClose && (
          <ModalPrimitive.Close
            asChild
            className={clsx('text-base-foreground absolute right-4 top-4', 'disabled:pointer-events-none')}
          >
            <Button iconLeading={<X size={16} />} variant="ghost" color="neutral" />
          </ModalPrimitive.Close>
        )}
        {(title || subtitle || icon) && (
          <ModalHeader
            iconPlacement={iconPlacement}
            headerAlignment={headerAlignment}
            title={title}
            subtitle={subtitle}
            icon={icon}
            bordered={headerBordered}
            className="py-5 px-6"
          />
        )}
        <div
          className={clsx('pb-5 px-6', {
            'py-5': !title && !subtitle && !icon,
          })}
        >
          {props.children}
        </div>
        {footer && (
          <ModalFooter bordered={footerBordered} className="py-5 px-6">
            {footer}
          </ModalFooter>
        )}
      </ModalContent>
    </ModalPrimitive.Root>
  )
);
Modal.displayName = 'Modal';

export default Modal;

export {
  ModalClose,
  ModalContent,
  ModalSubtitle as ModalDescription,
  ModalHeader,
  ModalOverlay,
  ModalPortal,
  ModalTitle,
  ModalTrigger,
};
