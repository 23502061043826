'use client';

import { useTheme } from 'next-themes';
import { toast, Toaster as Sonner } from 'sonner';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = 'system' } = useTheme();

  return (
    <Sonner
      theme={theme as ToasterProps['theme']}
      className="toaster group"
      toastOptions={{
        classNames: {
          toast:
            'group w-fit right-4 toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-stroke group-[.toaster]:shadow-high',
          description: 'group-[.toast]:text-subtle',
          actionButton: 'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
          cancelButton: 'group-[.toast]:bg-neutral-container group-[.toast]:text-on-neutral-container',
        },
      }}
      {...props}
    />
  );
};

export { toast, Toaster };
