import { X } from '@phosphor-icons/react/dist/ssr';
import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import React from 'react';
import { twMerge } from 'tailwind-merge';

const textColorVariants = cva([], {
  variants: {
    color: {
      primary: ['text-on-primary-container'],
      success: ['text-on-success-container'],
      warning: ['text-on-warning-container'],
      danger: ['text-on-danger-container'],
      info: ['text-on-info-container'],
      neutral: ['text-on-neutral-container'],
    },
  },
});

/* ---------------------------------- Type --------------------------------- */

interface BannerContainerProps {
  /** Color of the banner
   * @default 'primary'
   *
   */
  color?: BannerProps['color'];
  /** Variant of the banner
   *
   * @default 'default'
   */
  variant?: 'default' | 'soft';
}

/* ---------------------------------- Component --------------------------------- */

const bannerContainerVariants = cva(['relative flex h-fit w-full items-start gap-3 rounded-radius-md border p-6'], {
  variants: {
    color: {
      primary: ['border-stroke-primary'],
      success: ['border-stroke-success'],
      warning: ['border-stroke-warning'],
      danger: ['border-stroke-danger'],
      info: ['border-stroke-info'],
      neutral: ['border-stroke'],
    },
    variant: {
      default: [],
      soft: [],
    },
  },
  compoundVariants: (['primary', 'success', 'warning', 'danger', 'info', 'neutral'] as const).flatMap((color) => [
    {
      color,
      variant: 'default' as const,
      className: [`bg-${color}-container`],
    },
    {
      color,
      variant: 'soft' as const,
      className: [`bg-${color}-accent`],
    },
  ]),
});

const BannerContainer = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & BannerContainerProps>(
  ({ className, color = 'primary', variant, ...props }, ref) => (
    <div ref={ref} className={twMerge(clsx(bannerContainerVariants({ color, variant })), className)} {...props} />
  )
);
BannerContainer.displayName = 'BannerContainer';

/* ---------------------------------- Component --------------------------------- */

interface BannerTitleProps extends React.HTMLAttributes<HTMLDivElement> {
  /** Color of the banner
   * @default 'primary'
   */
  color?: BannerProps['color'];
}

const BannerTitle = React.forwardRef<HTMLDivElement, BannerTitleProps>(
  ({ color = 'primary', className, ...props }, ref) => (
    <div ref={ref} className={clsx('text-base font-semibold', textColorVariants({ color }), className)} {...props} />
  )
);
BannerTitle.displayName = 'BannerTitle';

/* ---------------------------------- Component --------------------------------- */

interface BannerDescriptionProps extends React.HTMLAttributes<HTMLDivElement> {
  /** Color of the banner
   * @default 'primary'
   */
  color?: BannerProps['color'];
}

const BannerDescription = React.forwardRef<HTMLDivElement, BannerDescriptionProps>(
  ({ color = 'primary', className, ...props }, ref) => (
    <div ref={ref} className={clsx('text-sm', textColorVariants({ color }), className)} {...props} />
  )
);
BannerDescription.displayName = 'BannerDescription';

/* ---------------------------------- Type --------------------------------- */

export interface BannerProps {
  /** Icon to the left of the title */
  icon?: React.ReactNode;
  /** Title of the banner */
  title: string;
  /** Description of the banner */
  description?: string;
  /** Color of the banner
   * @default 'primary'
   */
  color?: 'primary' | 'success' | 'warning' | 'danger' | 'info' | 'neutral';
  /** Variant of the banner
   * @default 'default'
   */
  variant?: 'default' | 'soft';
  /** Custom action below the title and description */
  action?: React.ReactNode;
  /** Default action below the title and description */
  callToAction?: string;
  /** Callback to be called when the call to action is clicked, exposes a close button when passed. */
  onCallToAction?: () => any;

  /** Callback to be called when the banner is closed, exposes a close button when passed. */
  onClose?: () => any;
  className?: string;
}

/* ---------------------------------- Component --------------------------------- */

const Banner = React.forwardRef<HTMLDivElement, BannerProps>(
  (
    { icon, title, description, color = 'primary', variant = 'default', callToAction, className = '', onClose, action },
    ref
  ) => {
    return (
      <BannerContainer ref={ref} className={className} color={color} variant={variant}>
        {onClose && (
          <button
            color={color}
            onClick={onClose}
            className={clsx(
              'absolute right-4 top-4 transition-colors',
              {
                'text-primary-sub hover:text-primary': color === 'primary',
                'text-success-sub hover:text-success': color === 'success',
                'text-warning-sub hover:text-warning': color === 'warning',
                'text-danger-sub hover:text-danger': color === 'danger',
              },
              className
            )}
            aria-label="Close banner"
          >
            <X size={16} />
          </button>
        )}

        <Slot className={textColorVariants({ color })}>{icon}</Slot>

        <div className="flex flex-col items-start gap-4">
          <div className="flex flex-col items-start gap-0.5">
            <BannerTitle color={color}>{title}</BannerTitle>
            {description && <BannerDescription color={color}>{description}</BannerDescription>}
          </div>
          {callToAction && (
            <button
              className={clsx('text-base underline transition-colors', {
                'text-primary hover:text-primary-hover active:text-primary-pressed': color === 'primary',
                'text-success hover:text-success-hover active:text-success-pressed': color === 'success',
                'text-warning hover:text-warning-hover active:text-warning-pressed': color === 'warning',
                'text-danger hover:text-danger-hover active:text-danger-pressed': color === 'danger',
                'text-info hover:text-info-hover active:text-info-pressed': color === 'info',
                'text-neutral hover:text-neutral-hover active:text-neutral-pressed': color === 'neutral',
              })}
            >
              {callToAction}
            </button>
          )}
          {action}
        </div>
      </BannerContainer>
    );
  }
);
Banner.displayName = 'Banner';

export default Banner;
