import { X } from '@phosphor-icons/react/dist/ssr';
import { type VariantProps, cva } from 'class-variance-authority';
import clsx from 'clsx';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { Drawer as DrawerPrimitive } from 'vaul';

import Button from './Button';

const DrawerTrigger = DrawerPrimitive.Trigger;

const DrawerClose = DrawerPrimitive.Close;

const DrawerPortal = DrawerPrimitive.Portal;

/* ---------------------------------- Component --------------------------------- */

const DrawerOverlay = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay> & {
    mask?: boolean;
  }
>(({ className, mask, ...props }, ref) => (
  <DrawerPrimitive.Overlay
    className={clsx(
      'fixed inset-0 z-50 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      {
        'bg-overlay': mask,
      },
      className
    )}
    {...props}
    ref={ref}
  />
));
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName;

/* ---------------------------------- Type --------------------------------- */

interface DrawerContentProps
  extends React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>,
    VariantProps<typeof drawerVariants> {
  /** Whether to display the dark overlay or not
   * @default true
   */
  mask?: boolean;
}

/* ---------------------------------- Component --------------------------------- */

const drawerVariants = cva(
  'fixed z-50 gap-4 border-stroke bg-background dark:shadow-none transition ease-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-300',
  {
    variants: {
      direction: {
        top: 'inset-x-0 top-0 border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top',
        bottom:
          'inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom',
        left: 'inset-y-0 left-0 h-full w-3/4 border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-sm',
        right:
          'inset-y-0 right-0 h-full w-3/4  border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm',
      },
    },
    defaultVariants: {
      direction: 'right',
    },
  }
);

const DrawerContent = React.forwardRef<React.ElementRef<typeof DrawerPrimitive.Content>, DrawerContentProps>(
  ({ direction = 'right', mask = true, className, children, ...props }, ref) => (
    <DrawerPortal>
      <DrawerOverlay mask={mask} />
      <DrawerPrimitive.Content ref={ref} className={clsx(drawerVariants({ direction }), className)} {...props}>
        {children}
      </DrawerPrimitive.Content>
    </DrawerPortal>
  )
);
DrawerContent.displayName = DrawerPrimitive.Content.displayName;

/* ---------------------------------- Type --------------------------------- */

export interface DrawerHeaderProps {
  /** Alignment of the drawer header
   * @default 'left'
   */
  headerAlignment?: 'left' | 'center';
  /** Icon to the left or above the title */
  icon?: React.ReactNode;
  /** Header icon placement
   * @default 'left'
   */
  iconPlacement?: 'left' | 'top';
  /** Title of the drawer */
  title?: string;
  /** Subtitle/description, below the title, of the drawer */
  subtitle?: string;
  /** Display a border under the header
   * @default true
   */
  bordered?: boolean;
  className?: string;
}

/* ---------------------------------- Component --------------------------------- */

const DrawerHeader = ({
  headerAlignment = 'left',
  iconPlacement = 'left',
  icon,
  title,
  subtitle,
  bordered,
  className,
}: DrawerHeaderProps) => (
  <div
    className={clsx(className, 'flex gap-2 p-6', {
      'flex-col': iconPlacement === 'top',
      'justify-center': headerAlignment === 'center',
      'items-center': iconPlacement === 'left' || (iconPlacement === 'top' && headerAlignment === 'center'),
      'border-b border-stroke': bordered,
    })}
  >
    {icon && <span>{icon}</span>}
    <div
      className={clsx('flex flex-col', {
        'items-center': iconPlacement === 'top' && headerAlignment === 'center',
      })}
    >
      {title && <DrawerTitle>{title}</DrawerTitle>}
      {subtitle && <DrawerSubtitle>{subtitle}</DrawerSubtitle>}
    </div>
  </div>
);
DrawerHeader.displayName = 'DrawerHeader';

/* ---------------------------------- Component --------------------------------- */

const DrawerFooter = ({
  className,
  bordered = true,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  bordered?: boolean;
}) => <div className={clsx(bordered && 'border-t border-stroke', className)} {...props} />;
DrawerFooter.displayName = 'DrawerFooter';

/* ---------------------------------- Component --------------------------------- */

const DrawerTitle = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Title ref={ref} className={clsx('text-sm font-semibold text-foreground', className)} {...props} />
));
DrawerTitle.displayName = DrawerPrimitive.Title.displayName;

/* ---------------------------------- Component --------------------------------- */

const DrawerSubtitle = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Description ref={ref} className={clsx('text-sm text-subtle', className)} {...props} />
));
DrawerSubtitle.displayName = DrawerPrimitive.Description.displayName;

/* ---------------------------------- Type --------------------------------- */

export interface DrawerProps extends Omit<DrawerHeaderProps, 'bordered'> {
  /** Add border bottom to the header
   * @default true
   */
  headerBordered?: boolean;
  /** Whether to show the close button
   * @default false
   */
  showClose?: boolean;
  /** Element that opens the drawer on click */
  trigger?: React.ReactNode;
  /** Footer of the drawer */
  footer?: React.ReactNode;
  /** Add border top to the footer
   * @default true
   */
  footerBordered?: boolean;
  /** Whether to display the dark overlay or not
   * @default true
   */
  mask?: boolean;
  className?: string;
}

/* ---------------------------------- Component --------------------------------- */

const Drawer = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Root>,
  DrawerProps & React.ComponentProps<typeof DrawerPrimitive.Root>
>(
  (
    {
      trigger,
      className,
      title,
      subtitle,
      headerAlignment = 'left',
      iconPlacement = 'left',
      icon,
      headerBordered = true,
      footer,
      footerBordered = true,
      showClose = false,
      mask = true,
      direction = 'right',
      shouldScaleBackground = true,
      ...props
    },
    ref
  ) => (
    <DrawerPrimitive.Root direction={direction} {...props} shouldScaleBackground={shouldScaleBackground}>
      {trigger && <DrawerTrigger asChild>{trigger}</DrawerTrigger>}
      <DrawerContent
        ref={ref}
        className={twMerge(
          clsx(
            'sm:min-w-96 flex max-h-full flex-col overflow-hidden',
            {
              'overflow-y-auto sm:max-w-lg': direction === 'left' || direction === 'right',
              'rounded-t-radius': direction === 'bottom',
            },
            className
          )
        )}
        mask={mask}
        direction={direction}
      >
        {showClose && (
          <DrawerPrimitive.Close
            className={clsx('text-base-foreground absolute right-4 top-4', 'disabled:pointer-events-none')}
          >
            <Button iconLeading={<X className="h-4 w-4" />} color="neutral" variant="ghost" size="sm" />
            <span className="sr-only">Close</span>
          </DrawerPrimitive.Close>
        )}
        {title && (
          <DrawerHeader
            title={title}
            subtitle={subtitle}
            icon={icon}
            bordered={headerBordered}
            headerAlignment={headerAlignment}
            iconPlacement={iconPlacement}
            className="p-6"
          />
        )}
        <div className={clsx('flex-1 overflow-y-auto p-6')}>{props.children}</div>
        {footer && (
          <DrawerFooter bordered={footerBordered} className="p-6 py-4">
            {footer}
          </DrawerFooter>
        )}
      </DrawerContent>
    </DrawerPrimitive.Root>
  )
);
Drawer.displayName = 'Drawer';

export default Drawer;

export {
  DrawerClose,
  DrawerContent,
  DrawerSubtitle as DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerPortal,
  DrawerPrimitive,
  DrawerTitle,
  DrawerTrigger,
};
