'use client';

import '../styles/globals.css';

import { api } from '@ion/api';
import { Toaster } from '@ion/design-system';
import type { Session } from '@ion/next-auth';
import { SessionProvider } from '@ion/next-auth/react';
import type { AppProps } from 'next/app';
import { Montserrat } from 'next/font/google';
import localFont from 'next/font/local';
import { DefaultSeo } from 'next-seo';

import { ThemeProvider } from '@/components/theme-provider';

const mont = Montserrat({
  subsets: ['latin-ext'],
  variable: '--font-montserrat',
});

export const GeistSans = localFont({
  src: [
    {
      path: './font/Geist-Thin.otf',
      weight: '100',
      style: 'normal',
    },
    {
      path: './font/Geist-UltraLight.otf',
      weight: '200',
      style: 'normal',
    },
    {
      path: './font/Geist-Light.otf',
      weight: '300',
      style: 'normal',
    },
    {
      path: './font/Geist-Regular.otf',
      weight: '400',
      style: 'normal',
    },
    {
      path: './font/Geist-Medium.otf',
      weight: '500',
      style: 'normal',
    },
    {
      path: './font/Geist-SemiBold.otf',
      weight: '600',
      style: 'normal',
    },
    {
      path: './font/Geist-Bold.otf',
      weight: '700',
      style: 'normal',
    },
    {
      path: './font/Geist-Black.otf',
      weight: '800',
      style: 'normal',
    },
    {
      path: './font/Geist-UltraBlack.otf',
      weight: '900',
      style: 'normal',
    },
  ],
  variable: '--font-geist-sans',
});

function MyApp({ Component, pageProps }: AppProps<{ session: Session }>) {
  return (
    <>
      {/* eslint-disable-next-line */}
      <style jsx global>{`:root { --font-geist-sans: ${GeistSans.style.fontFamily}}}`}</style>

      <DefaultSeo title="ion design" />
      <ThemeProvider attribute="class" defaultTheme="dark" enableSystem>
        <Toaster />
        <main className={`${mont.variable} ${GeistSans.variable} font-sans`}>
          <SessionProvider session={pageProps.session}>
            <Component {...pageProps} className={mont.className} />
          </SessionProvider>
        </main>
      </ThemeProvider>
    </>
  );
}

export default api.withTRPC(MyApp);
