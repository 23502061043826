import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';
export const env = createEnv({
  server: {
    FIGMA_CLIENT_SECRET: z.string().min(1),
    NODE_ENV: z.enum(['development', 'preview', 'production', 'test']).default('development'),
    SENTRY_AUTH_TOKEN: z.string().optional(),
    /*
    NEXTAUTH_URL: z.preprocess(
      // This makes Vercel deployments not fail if you don't set NEXTAUTH_URL
      // Since NextAuth.js automatically uses the VERCEL_URL if present.
      (str) => process.env.VERCEL_URL ?? str,
      // VERCEL_URL doesn't include `https` so it cant be validated as a URL
      process.env.VERCEL ? z.string().min(1) : z.string().url()
    ),
    */
    OPENAI_API_KEY: z.string().min(1),
    OPENAI_ORGANIZATION: z.string().min(1),
  },
  client: {
    NEXT_PUBLIC_BASE_URL: z.preprocess(
      // This makes Vercel deployments not fail if you don't set NEXTAUTH_URL
      // Since NextAuth.js automatically uses the VERCEL_URL if present.
      (str) => str ?? process.env.NEXT_PUBLIC_VERCEL_URL,
      // VERCEL_URL doesn't include `https` so it cant be validated as a URL
      process.env.NEXT_PUBLIC_VERCEL_URL ? z.string().min(1) : z.string().url()
    ),
    NEXT_PUBLIC_NODE_ENV: z.enum(['development', 'production', 'test']).default('development'),
    NEXT_PUBLIC_SENTRY_DSN: z.string().min(1),
    NEXT_PUBLIC_POSTHOG_KEY: z.string().min(1),
    NEXT_PUBLIC_POSTHOG_HOST: z.string().min(1),
    NEXT_PUBLIC_FIGMA_CLIENT_ID: z.string().min(1),
  },
  runtimeEnv: {
    FIGMA_CLIENT_SECRET: process.env.FIGMA_CLIENT_SECRET,
    SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,
    NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_BASE_URL: process.env.NEXT_PUBLIC_BASE_URL,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
    NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    NEXT_PUBLIC_FIGMA_CLIENT_ID: process.env.NEXT_PUBLIC_FIGMA_CLIENT_ID,
    OPENAI_API_KEY: process.env.OPENAI_API_KEY,
    OPENAI_ORGANIZATION: process.env.OPENAI_ORGANIZATION,
  },
});
